import React from "react";
//Components
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import Register from "./components/Register";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
// import Dashboard from "./Home"
import Video from "./Dashboard";
import Profile from "./profile/userProfile";
import publicProfile from "./profile/publicProfile";
import room from "./components/room";
import Admin from "./components/Admin";

import AdminUpdate from "./components/AdminUpdate";
import AdminDashboard from "./components/AdminDashboard";
import TermsCondition from "./components/Footer/Footer";
import Dashboard from "./components/dashboard";
import "axios-progress-bar/dist/nprogress.css";
import AdminRegister from "./components/AdminRegister";
import Contact from "./profile/contact";

import Miniapp from "./miniapp";
import Emailme from "./email";
import WaitingRoom from "./waitingRoom";
import Minilanding from "./miniLanding";
import messangerLanding from "./messangerLanding";
import screenShare from "./screenShare";
import Membership from "./Membership";
import errorPage from "./errorPage";
import "./App.css";

function App() {
  return (
    <div className="main">
      <Router>
        <>
          <Route exact path="/" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/private" component={Home} />
          <Route path="/videochat" component={Video} />
          <Route path="/forget" component={ForgetPassword} />
          <Route path="/error" component={errorPage} />
          <Route path="/eRDRVJMnbc3qExHQE2sn" component={Admin} />
          <Route
            path="/admindashboardeRDRVJMnbc3qExHQE2sn"
            component={AdminDashboard}
          />
          {/* <Route path="/adminsignup" component={AdminRegister}/> */}
          <Route
            path="/adminsignupeRDRVJMnbc3qExHQE2sn"
            component={AdminRegister}
          />
          <Route path="/adminupdate" component={AdminUpdate} />
          <Route path="/profile" component={Profile} />

          <Route path="/contact" component={Contact} />
          <Route path="/Membership" component={Membership} />
          <Route path="/screenShare/:id?/:userName?" component={screenShare} />
          <Route path="/TermsCondition" component={TermsCondition} />
          <Route path="/meetingScheduler" component={Emailme} />
          <Route path="/waitingRoom" component={WaitingRoom} />

          <Route path="/publicProfile" component={publicProfile} />
          <Route path="/room" component={room} />
          <Route exact path="/Miniapp/:id?/:userName?" component={Miniapp} />
          <Route
            exact
            path="/VEROHivemeetings/:id?/:userName?"
            component={Minilanding}
          />
          <Route path="/screenShare" component={Dashboard} />

          <Route
            exact
            path="/21AEF56E76A866F1161468CEBF5B23A9CE43F5E6319D050E498E77C02FDDD7BDcbvhjdferut4545347nvfrjhrt43734/:id?/:userName?"
            component={messangerLanding}
          />
        </>
        <TermsCondition />
      </Router>
    </div>
  );
}

export default App;
